import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import OGImage from 'images/about-us-featured.png'
import Logo from 'images/logo.png'


interface Props {
  data: IndexQueryQuery
  location: Location
}

const AboutPage: React.FC<Props> = ({ data, location }: Props) => {
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const office_section2_background = data.office_section2_background?.childImageSharp?.fluid
  const section3_img = data.section3_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#673ab7" />
      <title>About  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Want to know who we are?" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="About - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Want to know who we are?" />
      <meta property="og:url" content="https://thedentistsatgc.com/about" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2020-12-02T03:08:11+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content="https://thedentistsatgc.com/wp-content/uploads/2019/03/2018-01-1923.07.10.png" />
      <meta property="og:image:width" content="500" />
      <meta property="og:image:height" content="502" />
      <meta property="og:image:alt" content="About" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="About - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Want to know who we are?" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="Our Office - The Dentists at Gateway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section" className="text-center">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                Want to know who we are?
              </p>
              <h1 className="text-light">
                We're The Dentists At Gateway Crossing
              </h1>
            </div>
            <div className="col-lg-12">
              <p className="hero-text text-light m-t-20">
                Our focus is on people and our McCordsville community. At our office things are different, we care about you as a whole person, not just your teeth. Browse the page below to learn more about us.
              </p>
            </div>
            <div className="col-lg-12">
              <p className="hero-text text-light">
              Whenever you’re ready, it’s easy to schedule your appointment with us.  Just click “schedule now” below.
              </p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#office-section-1"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id ="office-section-1" className="page-section text-left">
        <Container>
          <div className="col-lg-12">
            <p className ="section-1-text"> We Care About People, Not Just Teeth. </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h2 className ="section-1-header"> About Our Practice </h2>
            <p>Our Practice is located right in the heart of McCordsville, IN. We’re not too far from Geist or Fishers, IN either. Our practice is built on certain values that hold us to a higher standard than most. As Dr. Vogt like to say, “when you’re here you’re family!” </p>
            <p>You can trust us to take great care of you and your loved ones. We focus on healthy teeth of course, but this is more than just a dental practice. This is a place for us to provide our neighbors with opportunities for better lives through oral health and to build a strong community.</p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h3 className ="section-1-header"> Our Mission </h3>
            <p>Our mission is to deliver an exceptional dental experience for our patients. Everything we do is centered around great clinical care, as well as your convenience and comfort. We thrive on positivity and strive to build relationships that last a lifetime. </p>
            <p>Whether you’re in need of a simple check-up or a total smile  makeover, Dr. Vogt invites you to be a part of our family here at The Dentists at Gateway Crossing.  </p>
          </div>
        </Container>
      </section>
      <BackgroundImage
        Tag="section"
        className="office-section2-bg"
        fluid={office_section2_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateaway Crossing Services"
      >
        <div className ="col-lg-12" id ="color-overlay">  </div>
        <section id="office-section-2" className="page-section">
          <Container>
            <div className="col-lg-6">
              <p className="hero-text">
                LOVE YOUR DENTIST!
              </p>
              <h3 className="text-light">
                Don't take another trip to the dentist that you don't enjoy!
              </h3>
              <p className="hero-text text-light">
                Your experience is our number one concern. It’s why so many people come to us for dental care. Call TODAY!
              </p>
              <a href="tel:3176439434" className="text-left"><button className="btn-rounded"><i className="fa fa-phone"></i> (317) 643-9434 </button></a>
            </div>
          </Container>
        </section>
      </BackgroundImage>
      <section id="office-section-3" className="page-section">
        <Container>
          <div className="col-lg-6">
            <Img fluid ={section3_img} alt="Dr. Matthew Vogt and the McCordsville Dentists Staff" />
          </div>
          <div className="col-lg-6 text-center">
            <p className="section-3-text"> We Only Hire The Best</p>
            <h3> You'll Love Our Friendly Staff</h3>
            <p>You’re going to love the way our staff treats you. Smiling faces and warm greetings makes our patients happy and comfortable. We want you to feel like you’re at home when you’re in our office. We know that going to the dentist is not always fun. That is why we made sure to hire polite and friendly people to be a part of our team.</p>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutPageQuery {
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_img: file(name: { eq: "section3-img" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    office_section2_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
